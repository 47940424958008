import styled from "styled-components";
import { BP, Rem } from "../../commons/Theme";

export const StyledBusinessImpact = styled.div`
  position: relative;
  width: 100%;
  color: white;
  text-align: center;
`;

export const StyledBusinessImpactTop = styled.div`
  position: absolute;
  width: 100%;
  padding: ${Rem(20)} ${Rem(10)};
  top: 0;
`;

export const StyledBusinessImpactBottom= styled.div`
  position: absolute;
  width: 100%;
  padding: ${Rem(20)} ${Rem(10)};
  bottom: 0;
`;

export const StyledBusinessImpactTitle = styled.div`
  font-size: ${Rem(25)};
  padding-bottom: ${Rem(10)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
  }
`;

export const StyledBusinessImpactSubtitle = styled.div`
  font-size: ${Rem(14)};
  font-weight: 300;
  padding: 0 ${Rem(10)};

  @media (${BP.ipad}) {
    font-size: ${Rem(24)};
  }
`;

export const StyledBusinessImpactItems = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-size: ${Rem(14)};
  flex-direction: column;
  gap: ${Rem(20)};

  @media (${BP.ipad}) {
    flex-direction: row;
    gap: 0;
    font-size: ${Rem(30)};
  }
`;

export const StyledBusinessImpactItemsGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  @media (${BP.ipad}) {
    justify-content: space-around;
  }
`;

export const StyledBusinessImpactItem = styled.div`
`;

export const StyledBusinessImpactItemSmaller = styled.div`
  font-size: ${Rem(12)};
  font-weight: 300;

  @media (${BP.ipad}) {
    font-size: ${Rem(20)};
  }
`;
